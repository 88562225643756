import BaseBackScreen from "../base-back-screen";
import RelaunchPromotionComponent from "./_layouts/relaunch";

export default class PromotionsScreen extends BaseBackScreen {
  name = "Promotions";

  loadOtherDialogContent(actionName, title = null, dataId = null) {
    let otherContent = {};
    if (actionName === "relaunch") {
      let data = {
        saveChanges: this.props.data.relaunch,
        promotionId: dataId,
      };

      otherContent = {
        actionName: actionName,
        componentName: title,
        pDialogRender: RelaunchPromotionComponent,
        data: data,
      };
    } else {
      otherContent = {
        actionName: actionName,
        componentName: title,
        dataReference: dataId,
        validAction: this.props.data?.screenActions?.validOtherAction,
      };
    }

    return otherContent;
  }
}
