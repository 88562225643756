import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

import TotalAmountRender from './total-amount/index';

const bestSellerFrameConfig = {
  name: 'bestseller',
  title: 'Meilleure(s) vente(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'articlecode',
      title: 'code',
    },
    {
      name: 'articleName',
      title: 'Désignation',
    },
    {
      name: 'qtySelled',
      title: 'QTE',
      type: 'number',
    },
    {
      name: 'sellValue',
      title: 'Vente',
      type: 'money',
    },
  ],
  filterOptions: [
    {
      name: 'familyId',
      title: 'Famille',
      type: 'select',
      optionsTarget: 'articlefamillies',
    },
    {
      name: 'subFamilyId',
      title: 'Sous famille',
      type: 'select',
      optionsTarget: 'articlesubfamillies',
    },
    {
      name: 'depotId',
      title: 'Depot',
      type: 'select',
      optionsTarget: 'depots',
    },
    {
      name: 'departementId',
      title: 'Departement',
      type: 'select',
      optionsTarget: 'departements',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  actions: [],
  componentBeforeRenderList: TotalAmountRender,
};

export default BaseConfiguration (bestSellerFrameConfig);
