import { BaseConfiguration } from "../../../vendor/base-component-class/config.class";

const promotionsFrameConfig = {
  name: "promotions",
  typeFrame: "list",
  fields: [
    {
      name: "name",
      title: "Nom",
      col: 6,
    },
    {
      name: "price",
      title: "Prix",
      type: "number",
      col: "6",
    },
    {
      name: "startDate",
      title: "Date début",
      type: "date",
      col: "6",
    },
    {
      name: "endDate",
      title: "Date fin",
      type: "date",
      col: "6",
    },
    {
      name: "isActive",
      title: "Statut",
      options: {
        0: { badgeColor: "#DC143C", text: "Inactif", textColor: "white" },
        1: { badgeColor: "#5F9EA0", text: "Actif" },
      },
      type: "checkBox",
    },
    {
      name: "promotionArticleList",
      title: "Produits",
      fields: [
        {
          name: "articleId",
          title: "Produit",
          htmlElement: "select",
          options: [],
          dataServiceSource: "products",
          fieldToTake: ["articleName"],
          type: "objectFetcher",
          fieldsSelected: ["articleName"],
          groupBy: ["subFamilyName"],
        },
        { name: "qty", title: "quantité", type: "number" },
      ],
      htmlElement: "dynamicForm",
    },
  ],
  actions: [
    {
      name: "view",
      title: "Voir",
      color: "info",
      icon: "eye",
    },
    {
      name: "edit",
      title: "Changer état",
      color: "success",
      icon: "pen",
    },
    {
      name: "relaunch",
      title: "Relance",
      color: "dark",
      icon: "refresh",
      elementTest: "isActive",
      value: false,
      operator: "equal",
    },
    {
      name: "delete",
      title: "Supprimer",
      color: "danger",
      icon: "trash",
    },
  ],
  excludeToForms: ["isActive"],
  excludeToHeaders: ["promotionArticleList"],
  filterOptions: [
    {
      name: "name",
      title: "nom",
    },
    {
      name: "isActive",
      title: "Statut",
      type: "select",
      options: [
        { label: "Actif", value: true },
        { label: "Inactif", value: false },
      ],
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: "md",
    },
    singleDialog: {
      size: "md",
    },
  },
};

export default BaseConfiguration(promotionsFrameConfig);
