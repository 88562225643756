import PromotionsService from "../../../external-communication/services/promotions.service";
import BaseBackComponent from "../base-back-component";

export default class PromotionsComponent extends BaseBackComponent {
  name = "Promotions";

  constructor(props) {
    super(props);
    this.relaunch = this.relaunch.bind(this);

    this.state = {
      ...this.state,
      relaunch: this.relaunch,
    };
  }

  options = [
    {
      title: "products",
      keys: ["articleName"],
      optionValue: "articleId",
      dataToGetForGroupTitle: ["subFamilyName"],
      imgTarget: "image",
      source: "articles",
      method: "articlesWithPricing",
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      name: null,
    },
  };

  componentDidMount() {
    this.initDataLoading();
    this.loadOptions();
  }

  async relaunch(e, credentials) {
    e.preventDefault();
    const response = await new PromotionsService().reload(credentials);

    if (response.success) {
      this.loadFormMessage(
        "success",
        response.message || "Succès relance de la promotion"
      );
      this.setState({ relaunchDialog: !this.state.relaunchDialog });
      this.initDataLoading();
    } else {
      this.loadFormMessage(
        "error",
        response.message || "Echec relance de la promotion"
      );
    }
  }

  otherSingleDataInit(data) {
    const promotionArticleList = data?.promotionArticleList.value.map(
      (article) => {
        return {
          articleId: { value: article.article.id },
          qty: { value: article.qty },
        };
      }
    );
    data = {
      ...data,
      promotionArticleList: { value: promotionArticleList },
    };
    return data;
  }
}
