import Service from '../../vendor/bases/service';

export default class ArticlesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'articles',
    });
  }

  getInventory = async articleId => {
    return await this.treatQuery (this.query.getArticleInventory (articleId));
  };

  async getClosedInventory (options = {}) {
    return await this.treatQuery (this.query.getCloseInventory (options));
  }

  async onlyFinishedProducts () {
    return await this.treatQuery (this.query.onlyFinishedProducts ());
  }

  async excludeFinishedProducts () {
    return await this.treatQuery (this.query.excludeFinishedProducts ());
  }

  async onlyRawMaterials () {
    return await this.treatQuery (this.query.onlyRawMaterials ());
  }

  async onlyFinishedProductsAndNegoce () {
    return await this.treatQuery (this.query.onlyFinishedProductsAndNegoce ());
  }

  async onlyFinishedProductsAndRawMaterials () {
    return await this.treatQuery (
      this.query.onlyFinishedProductsAndRawMaterials ()
    );
  }

  async articlePrice (articleId) {
    return await this.treatQuery (this.query.getArticlePrice (articleId));
  }

  async articlesWithPricing () {
    return await this.treatQuery (this.query.getArticlesWithPrice ());
  }

  async lockOrUnlock(articleId){
    return await this.treatQuery(this.query.disable(articleId));
  }

  async createArticleStock(payLoad){
    let result = await  this.treatQuery (this.query.createAddStock (this.getFormValues(payLoad)));
    if(!result?.success && result?.errors?.length > 0){
     result.errors = this.giveErrorToTheGoodField(result.errors,payLoad);
    }
    return result;
  }
}
