import "./single.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import profile from "../../../../../assets/images/logo.png";
import moment from "moment";

const SinglePromotionRender = (props) => {
  const promotionInformation = props.data.rows;

  return (
    <div className="client-card-information">
      <div className="mt-2 p-2">
        <div className="client-content">
          <div className="p-2 text-center text-white client-title">
            <h4>FICHE PROMOTION #{promotionInformation?.id}</h4>
          </div>
          <div className="card p-3 m-1">
            <div className="d-flex align-items-center">
              <div className="image bg-light p-2 mr-3">
                <img src={profile} alt="logo" className="rounded" width="100" />
              </div>
              <div className="p-2 w-100">
                <h5 className="mb-0 mt-0">{promotionInformation?.name}</h5>
                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                  <div className="d-flex flex-column">
                    <span className="articles">Prix</span>
                    <span className="number1">
                      {Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "XOF",
                      }).format(promotionInformation?.price)}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="articles">Debut</span>
                    <span className="number1">
                      {moment(promotionInformation?.startDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="articles">Fin</span>
                    <span className="number1">
                      {moment(promotionInformation?.endDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead
                    sx={{
                      "& th": {
                        color: "white",
                        backgroundColor: "black",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        DESIGNATION
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        QTE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promotionInformation?.promotionArticleList?.length > 0 ? (
                      promotionInformation?.promotionArticleList?.map(
                        (row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="td"
                              sx={{ fontWeight: "bold" }}
                              scope="articleName"
                            >
                              {row?.article?.name.toUpperCase()}
                            </TableCell>

                            <TableCell
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                            >
                              {row?.qty}
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{ textAlign: "center" }}
                          colSpan={5}
                          component="td"
                          scope="row"
                        >
                          Aucun article sur cette fiche de vente :)
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center image-text border-top">
          <div className="text logo-text">
            <span className="name"> PIKADO</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePromotionRender;
