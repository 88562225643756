import { Fragment, useState } from "react";
import FrameTitle from "../../../../../vendor/builders/Frame/_layouts/title";
import { InputTypeDate } from "../../../../../vendor/builders/Form/Input";

export default function RelaunchPromotionComponent(props){
    const data = props?.data?.data;

    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);


    const changeStartDate = (e,value) => {
        setStartDate(value);
    }

    const changeEndDate = (e,value) => {
        setEndDate(value);
    }

    const saveChange = (e) => {
        data.saveChanges(e,{
            startDate : {value : startDate},
            endDate : {value : endDate},
            promotionId : {value : data.promotionId},
        })
    }

    return  <Fragment>
                <FrameTitle
                    name="PROMOTION RELAUNCH"
                    title="RELANCE PROMOTION"
                    openDialog={(e) => {}}
                    actions={[]}
                />

                <div className="row">
                    <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                        <InputTypeDate
                          changeValue={changeStartDate}
                          value={startDate}
                          fieldInformation = {{
                            name : "startDate",
                            id : "startDate",
                            label : "Date debut"
                          }}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                    <InputTypeDate
                          changeValue={changeEndDate}
                          value={endDate}
                          fieldInformation = {{
                            name : "endDate",
                            id : "endDate",
                            label : "Date fin"
                          }}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                   <button className="btn btn-primary" onClick={saveChange}>
                          RELANCER
                    </button>       
                </div>
            </Fragment>
}