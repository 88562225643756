import Service from "../../vendor/bases/service";

export default class PromotionsService extends Service {
  constructor(params) {
    super({
      ...params,
      name: "Promotions",
    });
  }

  async reload(payload) {
    const credentials = this.getFormValues(payload);
    return await this.treatQuery(this.query.reload(credentials));
  }
}
