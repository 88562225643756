import { useState } from "react";
import { Autocomplete, Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import DateRangePickerValue from "../../../../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date";

export default function AnalysisMarginRender (props) {
  let renderConfigs = props.data;

  const changeDepartment = (e,value = null) => {
    if(value){
      renderConfigs.screenActions.handleSearch(e,value?.value,"departementId");
    }else{
        renderConfigs.screenActions.handleSearch(e,null,"departementId");
    }
    setDepartment(value);
  }

  const [department,setDepartment] = useState();


  const changeFamily = (e,value = null) => {
    if(value){
      renderConfigs.screenActions.handleSearch(e,value?.value,"familyId");
    }else{
        renderConfigs.screenActions.handleSearch(e,null,"familyId");
    }
    setFamily(value);
  }

  const [family,setFamily] = useState();


  const changeSubFamily = (e,value = null) => {
    if(value){
      renderConfigs.screenActions.handleSearch(e,value?.value,"subFamilyId");
    }else{
        renderConfigs.screenActions.handleSearch(e,null,"subFamilyId");
    }
    setSubFamily(value);
  }

  const changeDateValue = (e, value = null) => {
    renderConfigs.screenActions.handleSearch (e, value, 'date');
  };

  const [subFamily,setSubFamily] = useState();


  const departments = renderConfigs?.options?.find(option => option.title === "departements")?.options || [];
  const famillies = renderConfigs?.options?.find(option => option.title === "articlefamillies")?.options || [];
  const subfamillies = renderConfigs?.options?.find(option => option.title === "articlesubfamillies")?.options || [];

  const data = renderConfigs?.data || [];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return <div className="">
             <div className="title text-white p-2">
                ANALYSE DES MARGES
            </div>

            <div className="col-12 d-flex justify-content-start my-3">
                <DateRangePickerValue
                  size="small"
                  changeSearchValue={changeDateValue}
                  data={{
                    name: 'date',
                  }}
                />
                <Autocomplete
                    id="departementId"
                    options={departments}
                    onChange={(e,value) => {
                        changeDepartment(e,value)
                    }}
                    getOptionLabel={option => option.label}
                    isOptionEqualToValue={option => option.value === department?.value}
                    size="small"
                    sx={{ width:150,mr:1 }}
                    renderInput={(params) => <TextField {...params} label={`Departement`} />}
                    value={department}
                />
                <Autocomplete
                    id="familyId"
                    options={famillies}
                    onChange={(e,value) => {
                        changeFamily(e,value)
                    }}
                    getOptionLabel={option => option.label}
                    isOptionEqualToValue={option => option.value === family?.value}
                    size="small"
                    sx={{ width:150,mr:1 }}
                    renderInput={(params) => <TextField {...params} label={`Familles d'article`} />}
                    value={family}
                />
                <Autocomplete
                    id="subFamilyId"
                    options={subfamillies}
                    onChange={(e,value) => {
                        changeSubFamily(e,value)
                    }}
                    getOptionLabel={option => option.label}
                    isOptionEqualToValue={option => option.value === subFamily?.value}
                    size="small"
                    sx={{ width:150,mr:1 }}
                    renderInput={(params) => <TextField {...params} label={`Sous famille d'article`} />}
                    value={subFamily}
                />
            </div>
            
            <Box sx={{ width: '100%' }}>     
              <Paper sx={{ width: '100%', overflow:'hidden' }}>
                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader size={'small'}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          CODE ARTICLE
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          DESIGNATION
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={2}>
                          VENTE
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={2}>
                          COUT DE REVIENT
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={2}>
                          VALEUR WASTE
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          BENEFICE
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          % QTE
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          QTE
                        </TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          VENTE
                        </TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          QTE
                        </TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          VALEUR
                        </TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          QTE
                        </TableCell>
                        <TableCell
                            align={"center"}
                            
                        >
                          VALEUR
                        </TableCell>
                        <TableCell
                            align={"center"}
                        >
                          QTE
                        </TableCell>
                        <TableCell
                            align={"center"}
                        >
                          VALEUR
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                          option => <StyledTableRow key={option.articleId}>
                                        <StyledTableCell align="center">
                                          {option?.articlecode}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.articleName}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.qtySelled?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.sellValue?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.costOfPurchase?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.qtyWaste?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.wasteValue?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                         <StyledTableCell align="center">
                                          {option?.margin?.toLocaleString('fr-FR')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {option?.succesPercent?.toLocaleString('fr-FR') + "%"}
                                        </StyledTableCell>
                                    </StyledTableRow>

                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />     
              </Paper>  
            </Box>
   </div>;
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
