import DefaultQuery from "../../vendor/bases/default.query";

export default class PromotionsQuery extends DefaultQuery {
  constructor(params = null) {
    super({
      ...params,
      path: "promotion",
    });
  }

  async create(credentials) {
    return await this.createMethod(this.buildPath("add"), credentials);
  }

  async readAll(params = null) {
    return await this.getMethod(this.buildPath("list"), params);
  }

  async readOne(id) {
    return await this.getMethod(this.buildPath(`detail/${id}`));
  }

  async update(id, payLoad) {
    return await this.updateMethod(this.buildPath(`update/${id}`), payLoad);
  }

  async reload(payLoad) {
    return await this.createMethod(
      this.buildPath(`relaunch-promotion`),
      payLoad
    );
  }

  async delete(id) {
    return await this.deleteMethod(this.buildPath(`delete/${id}`));
  }
}
